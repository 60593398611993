import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//admin routing
const OrderPage = Loadable(lazy(() => import('views/user/OrderPage')));
const UserList = Loadable(lazy(() => import('views/admin/user-list')));
const PackageManagementPage = Loadable(lazy(() => import('views/admin/PackageManagementPage')));
const AccountTypeManagementPage = Loadable(lazy(() => import('views/admin/AccountTypeManagementPage')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <OrderPage />
    },
    {
      path: 'user-management',
      element: <UserList />
    },
    {
      path: 'setting/package-management',
      element: <PackageManagementPage />
    },
    { path: '/setting/account-type', element: <AccountTypeManagementPage /> }
  ]
};

export default AdminRoutes;
