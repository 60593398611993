// assets
import { IconExclamationCircle } from '@tabler/icons';

// constant
const icons = { IconExclamationCircle };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const support = {
  id: 'contact',
  type: 'group',
  role: 'user',
  children: [
    {
      id: 'contact',
      title: 'Liên hệ',
      type: 'item',
      url: '/contact',
      icon: icons.IconExclamationCircle,
      breadcrumbs: false
    }
  ]
};

export default support;
