import * as authConstants from '../constants/AuthConstants';

export const registerUser = (data) => {
  return {
    type: authConstants.REGISTER_USER,
    payload: data
  };
};

export const registerUserSucess = (data) => {
  return {
    type: authConstants.REGISTER_USER_SUCCESS,
    payload: data
  };
};

export const registerUserFail = (error) => {
  return {
    type: authConstants.REGISTER_USER_FAIL,
    payload: error
  };
};

export const loginUser = (data) => {
  return {
    type: authConstants.LOGIN_USER,
    payload: data
  };
};

export const loginUserSuccess = (data) => {
  return {
    type: authConstants.LOGIN_USER_SUCCESS,
    payload: data
  };
};

export const loginUserFail = (error) => {
  return {
    type: authConstants.LOGIN_USER_FAIL,
    payload: error
  };
};

export const logoutUser = () => {
  return {
    type: authConstants.LOGOUT_USER
  };
};
