/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from 'utils/constants';
import '../src/assets/scss/style.scss';
import { axiosConfig } from 'axiosConfig';
// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const location = useLocation();
  const dataUser = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    axiosConfig(dataUser?.token);
    const isAuthPath =
      location.pathname === PATH.FORGOT_PASSWORD || location.pathname === PATH.REGISTER || location.pathname === PATH.LOGIN;
    if (isAuthPath) {
      if (dataUser?.token) navigate(PATH.HOMEPAGE);
      else navigate(location.pathname);
    }
  }, [dataUser?.token, location.pathname]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes isAdmin={dataUser?.isAdmin} />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
