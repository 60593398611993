import { call, put, takeEvery } from 'redux-saga/effects';
import * as userConstants from '../constants/UserConstants';
import * as userServices from '../../services/userService';
import 'react-toastify/dist/ReactToastify.css';
import { getAllUserFail, getAllUserSuccess, getUserByIdFail, getUserByIdSucess } from 'store/actions/UserActions';
import { SUCCESS } from 'utils/constants';
import { toast } from 'react-toastify';

function* handleGetAllUser(action) {
  try {
    const res = yield call(userServices.getAllUser, action?.payload.page, action?.payload.rowPerPage, action?.payload.keyword);
    if (res?.status === SUCCESS) {
      yield put(getAllUserSuccess(res?.data));
    }
  } catch (error) {
    yield put(getAllUserFail(error));
  }
}

function* handleGetUserById(action) {
  try {
    const id = action.payload;
    const res = yield call(userServices.getUserById, id);
    if (res?.status === SUCCESS) {
      yield put(getUserByIdSucess(res?.data));
    }
  } catch (error) {
    yield put(getUserByIdFail(error));
  }
}

function* handleUpdateUser(action) {
  try {
    const data = action.payload;

    const res = yield call(userServices.updateUserApi, data);
    if (res?.status === SUCCESS) {
      toast.success('Cập nhật thông tin thành công');
      yield put(getUserByIdSucess(res?.data));
    }
  } catch (error) {
    toast.error('Cập nhật thông tin thất bại');
    yield put(getUserByIdFail(error));
  }
}

function* userSaga() {
  yield takeEvery(userConstants.GET_USER_BY_ID, handleGetUserById);
  yield takeEvery(userConstants.UPDATE_USER, handleUpdateUser);
  yield takeEvery(userConstants.GET_ALL_USER, handleGetAllUser);
}
export default userSaga;
