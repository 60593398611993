import * as userConstants from '../constants/UserConstants';

const initialState = {
  user: {},
  listUser: [],
  totalElements: 0,
  loading: false,
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.GET_USER_BY_ID:
    case userConstants.UPDATE_USER:
    case userConstants.GET_ALL_USER:
      return {
        ...state,
        loading: true,
        error: false
      };

    case userConstants.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        listUser: action?.payload?.data,
        totalElements: action?.payload?.total
      };
    case userConstants.GET_USER_BY_ID_SUCCESS:
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case userConstants.GET_USER_BY_ID_FAIL:
    case userConstants.UPDATE_USER_FAIL:
    case userConstants.GET_ALL_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default userReducer;
