import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Icon,
  InputAdornment,
  Typography
} from '@mui/material';
import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import '../../../assets/scss/_dialog.scss';
import { Close, MailOutline } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '../../../utils/validate';
import { PROFILE_SECTION, SUCCESS, TYPE_PASSWORD } from 'utils/constants';
import { useState } from 'react';
import { changePassword } from 'services/userService';
import { toast } from 'react-toastify';
import { alertMessage } from 'utils/alert';
import { Box } from '@mui/system';
export const ChangePass = (props) => {
  const { openDialogChangePass, handleCloseDialogChangePass, user } = props;
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [password, setPassword] = useState({ oldPassword: '', newPassword: '' });
  const dataUser = JSON.parse(localStorage.getItem('user'));

  const handleSubmit = async () => {
    try {
      const res = await changePassword(user._id, password);
      if (res?.status === SUCCESS) {
        toast.success('Đổi mật khẩu thành công.');
        handleCloseDialogChangePass();
      }
    } catch (error) {
      const message = error.response.data.message;
      alertMessage(message);
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setPassword({
      ...password,
      [name]: value
    });
  };

  const handleTogglePassword = (type) => {
    if (type === TYPE_PASSWORD?.OLD?.id) {
      setShowPasswordOld(!showPasswordOld);
    } else if (type === TYPE_PASSWORD?.NEW?.id) {
      setShowPasswordNew(!showPasswordNew);
    } else {
      setShowPasswordConfirm(!showPasswordConfirm);
    }
  };
  return (
    <>
      <Dialog maxWidth="xs" fullWidth={true} open={openDialogChangePass} onClose={handleCloseDialogChangePass}>
        <DialogTitle className="dialogTitle">
          <span className="title">{PROFILE_SECTION?.CHANGEPASS?.name}</span>
          <IconButton onClick={() => handleCloseDialogChangePass()}>
            <Icon color="error" title="Đóng">
              <Close />
            </Icon>
          </IconButton>
        </DialogTitle>
        <ValidatorForm onSubmit={handleSubmit}>
          <DialogContent>
            <Box display="flex" justifyContent="center" gap="8px" marginBottom="10px">
              <MailOutline color="primary" />
              <Typography lineHeight={1.8}>{user.email}</Typography>
            </Box>

            <Grid container spacing={2}>
              {user._id === dataUser._id && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    className="w-100"
                    variant="outlined"
                    label={
                      <span className="font">
                        <span className="red"> * </span>
                        {TYPE_PASSWORD?.OLD?.name}
                      </span>
                    }
                    type={showPasswordOld ? 'text' : 'password'}
                    value={password?.oldPassword || ''}
                    name="oldPassword"
                    size="small"
                    validators={['required']}
                    errorMessages={['Trường này không được để trống']}
                    onChange={handleOnChange}
                    InputProps={{
                      endAdornment: password?.oldPassword && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleTogglePassword(TYPE_PASSWORD?.OLD?.id)}>
                            {showPasswordOld ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextValidator
                  className="w-100"
                  variant="outlined"
                  label={
                    <span className="font">
                      <span className="red"> * </span>
                      {TYPE_PASSWORD?.NEW?.name}
                    </span>
                  }
                  type={showPasswordNew ? 'text' : 'password'}
                  value={password?.newPassword || ''}
                  name="newPassword"
                  size="small"
                  validators={['required', 'isPasswordValid']}
                  errorMessages={[
                    'Trường này không được để trống',
                    'Mật khẩu bắt buộc phải có ít nhất 8 ký tự, bao gồm cả chữ hoa, chữ thường và số. Mật khẩu có thể bao gồm những ký tự đặc biệt: @ # $ ! % * ? &'
                  ]}
                  onChange={handleOnChange}
                  InputProps={{
                    endAdornment: password?.newPassword && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleTogglePassword(TYPE_PASSWORD?.NEW?.id)}>
                          {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button variant="contained" color="error" onClick={() => handleCloseDialogChangePass()}>
              Hủy
            </Button>
            <Button variant="contained" color="secondary" type="submit">
              Xác nhận
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </>
  );
};

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = (dispatch) => {
//   return {};
// };

export default connect(null, null)(ChangePass);
