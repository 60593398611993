export const convertNumberWithDot = (value) => {
  return Number(value).toLocaleString('de-DE');
};

export const convertNumberToVND = (value) => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  });

  return formatter.format(Number(value));
};

export const keepNumberOfString = (value) => {
  const numberString = String(value).replace(/\D/g, ''); // loại bỏ tất cả các ký tự không phải số
  return numberString;
};

export const convertOrderTypeToVie = (value) => {
  if (value === 'upgradeAccount') return 'Nâng cấp tài khoản';
  if (value === 'buyPackage') return 'Mua gói gia hạn';
  if (value === 'recharge') return 'Nạp tiền vào ví';
  return '';
};
