/* eslint-disable react/prop-types */
import { Dialog, DialogTitle, Grid, DialogContent, DialogActions, Button, IconButton, Icon, Checkbox } from '@mui/material';
import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import '../../../assets/scss/_dialog.scss';
import { Close } from '@mui/icons-material';
import { PROFILE_SECTION } from 'utils/constants';
import { useState } from 'react';
import { updateUser } from 'store/actions/UserActions';
import { updateUserApi } from 'services/userService';
import { toast } from 'react-toastify';
export const InforUser = (props) => {
  const { openDiaLogInfor, handleCloseDiaLogInfor, user, updateUser, admin, handleGetAllUser } = props;
  const [userInfor, setUserInfor] = useState(user);
  const handleSubmit = async () => {
    if (admin) {
      try {
        const res = await updateUserApi(userInfor);

        if (res?.status === 200) {
          toast.success('Cập nhật thông tin thành công');
          handleGetAllUser();
        }
      } catch (error) {
        console.log(error, 'check');
      }
      console.log('admin');
    } else {
      updateUser(userInfor);
    }
    handleCloseDiaLogInfor();
  };
  const handleOnChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'isAdmin' || name === 'isActive') {
      setUserInfor({
        ...userInfor,
        [name]: checked
      });
    } else {
      setUserInfor({
        ...userInfor,
        [name]: value
      });
    }
  };
  return (
    <>
      <Dialog maxWidth="xs" fullWidth={true} open={openDiaLogInfor} onClose={handleCloseDiaLogInfor}>
        <DialogTitle className="dialogTitle">
          <span className="title">{admin ? 'Sửa thông tin' : PROFILE_SECTION.INFOR.name}</span>
          <IconButton onClick={() => handleCloseDiaLogInfor()}>
            <Icon color="error" title="Đóng">
              <Close />
            </Icon>
          </IconButton>
        </DialogTitle>
        <ValidatorForm onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextValidator
                  className="w-100"
                  variant="outlined"
                  label={
                    <span className="font">
                      <span className="red"> * </span>
                      Email
                    </span>
                  }
                  type="text"
                  value={userInfor?.email || ''}
                  name="email"
                  size="small"
                  validators={['required']}
                  errorMessages={['Trường này không được để trống']}
                  disabled
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextValidator
                  className="w-100"
                  variant="outlined"
                  label={
                    <span className="font">
                      <span className="red"> * </span>
                      Số điện thoại
                    </span>
                  }
                  type="text"
                  value={userInfor?.phone || ''}
                  name="phone"
                  size="small"
                  validators={['required']}
                  errorMessages={['Trường này không được để trống']}
                  onChange={handleOnChange}
                  disabled
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextValidator
                  className="w-100"
                  variant="outlined"
                  label={
                    <span className="font">
                      <span className="red"> * </span>
                      Họ và tên
                    </span>
                  }
                  type="text"
                  value={userInfor?.name || ''}
                  name="name"
                  size="small"
                  validators={['required']}
                  errorMessages={['Trường này không được để trống']}
                  onChange={handleOnChange}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextValidator
                  className="w-100"
                  variant="outlined"
                  label={
                    <span className="font">
                      <span className="red"> * </span>
                      Địa chỉ
                    </span>
                  }
                  type="text"
                  value={userInfor?.address || ''}
                  name="address"
                  size="small"
                  validators={['required']}
                  errorMessages={['Trường này không được để trống']}
                  onChange={handleOnChange}
                />
              </Grid>
              {admin && (
                <>
                  <Grid item lg={5} md={5} sm={12} xs={12} ml={2}>
                    <span>Admin:</span>
                    <Checkbox checked={userInfor?.isAdmin} onChange={handleOnChange} name="isAdmin" color="success" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} ml={2}>
                    <span>Hoạt động:</span>
                    <Checkbox checked={userInfor?.isActive} onChange={handleOnChange} name="isActive" color="success" />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button variant="contained" color="error" onClick={() => handleCloseDiaLogInfor()}>
              Hủy
            </Button>
            <Button variant="contained" color="secondary" type="submit">
              Xác nhận
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </>
  );
};

// const mapStateToProps = (state) => ({
// });

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data))
  };
};

export default connect(null, mapDispatchToProps)(InforUser);
