import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './AuthReducer';
import userReducer from './UserReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  user: userReducer
});

export default rootReducer;
