/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useState, useRef, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import UpgradePlanCard from './UpgradePlanCard';
// import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconKey, IconLogout, IconUser } from '@tabler/icons';
import { PROFILE_SECTION } from 'utils/constants';
import Infor from 'views/user/infor';
import { ChangePass } from 'views/user/changepass';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { PersonOutlined } from '@mui/icons-material';
import { BalanceContext } from 'contexts/BalanceContext';
import { convertNumberToVND } from 'utils/convert';
import { getUserBalance } from 'services/WalletService';
import { logoutUser } from 'services/authService';
import { toast } from 'react-toastify';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [openDiaLogInfor, setOpenDialogInfor] = useState(false);
  const [openDialogChangePass, setOpenDialogChangepass] = useState(false);
  const { balanceContextData, dispatch } = useContext(BalanceContext);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */

  const anchorRef = useRef(null);

  useEffect(() => {
    handleGetUserBalance();
  }, [currentUser._id]);

  const handleGetUserBalance = () => {
    getUserBalance(currentUser._id).then((res) => dispatch({ type: 'setWalletBalance', payload: res.data }));
  };

  const handleLogout = async () => {
    await logoutUser()
      .then(() => {
        localStorage.removeItem('user');
        toast.success('Đăng xuất tài khoản thành công.');
        navigate('/login');
      })
      .catch(() => toast.error('Đăng xuất tài khoản thất bại.'));
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    handleClose(event);
    if (PROFILE_SECTION.INFOR.id === index) {
      setOpenDialogInfor(true);
    } else if (PROFILE_SECTION.CHANGEPASS.id === index) {
      setOpenDialogChangepass(true);
      console.log('OKE CHANGE PASS');
    } else {
      console.log('UPDATE SAU');
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseDiaLog = () => {
    setOpenDialogInfor(false);
    setOpenDialogChangepass(false);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box display="flex" gap="10px">
        {!currentUser.isAdmin && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography fontSize="14px" fontWeight={500} color="#ffffff">
              {convertNumberToVND(balanceContextData.walletBalance)}
            </Typography>
            <IconButton disableRipple onClick={() => navigate('/wallet')}>
              <AccountBalanceWalletOutlinedIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </div>
        )}
        <IconButton disableRipple>
          <NotificationsOutlinedIcon sx={{ color: '#ffffff' }} />
        </IconButton>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
          <Chip
            sx={{
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.primary.light,
              backgroundColor: theme.palette.primary.light,
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.primary.main,
                background: `${theme.palette.primary.main}!important`,
                color: theme.palette.primary.light,
                '& svg': {
                  stroke: theme.palette.primary.light
                }
              },
              '& .MuiChip-label': {
                lineHeight: 0
              }
            }}
            // icon={
            //   <Avatar
            //     src={User1}
            //     sx={{
            //       ...theme.typography.mediumAvatar,
            //       margin: '8px 0 8px 8px !important',
            //       cursor: 'pointer'
            //     }}
            //     ref={anchorRef}
            //     aria-controls={open ? 'menu-list-grow' : undefined}
            //     aria-haspopup="true"
            //     color="inherit"
            //   />
            // }
            label={<PersonOutlined stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
          />
          <Typography fontSize="14px" fontWeight={500} color="#ffffff">
            {currentUser.name}
          </Typography>
        </div>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">{currentUser?.name}</Typography>
                        {/* <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          Hoàng Anh
                        </Typography> */}
                      </Stack>

                      <Typography variant="subtitle2">
                        {currentUser?.isAdmin ? PROFILE_SECTION.ADMIN.name : PROFILE_SECTION.USER.name}
                      </Typography>
                    </Stack>
                    {/* <OutlinedInput
                      sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                      id="input-search-profile"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      placeholder="Search profile options"
                      startAdornment={
                        <InputAdornment position="start">
                          <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                        </InputAdornment>
                      }s
                      aria-describedby="search-helper-text"
                      inputProps={{
                        'aria-label': 'weight'
                      }}
                    /> */}
                    {/* <Divider /> */}
                  </Box>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 2 }} paddingTop={'0px !important'}>
                      {/* <UpgradePlanCard /> */}
                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 1}
                          onClick={(event) => handleListItemClick(event, PROFILE_SECTION.INFOR.id)}
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2">{PROFILE_SECTION.INFOR.name}</Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={(event) => handleListItemClick(event, PROFILE_SECTION.CHANGEPASS.id)}
                        >
                          <ListItemIcon>
                            <IconKey stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">{PROFILE_SECTION.CHANGEPASS.name}</Typography>} />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">{PROFILE_SECTION.LOGOUT.name}</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {openDiaLogInfor && <Infor openDiaLogInfor={openDiaLogInfor} handleCloseDiaLogInfor={handleCloseDiaLog} user={currentUser} />}
      {openDialogChangePass && (
        <ChangePass openDialogChangePass={setOpenDialogChangepass} handleCloseDialogChangePass={handleCloseDiaLog} user={currentUser} />
      )}
    </>
  );
};

export default ProfileSection;
