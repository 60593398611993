import * as authConstants from '../constants/AuthConstants';

const initialState = {
  user: {},
  loading: false,
  error: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.REGISTER_USER:
    case authConstants.LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: false
      };
    case authConstants.REGISTER_USER_SUCCESS:
    case authConstants.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case authConstants.REGISTER_USER_FAIL:
    case authConstants.LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case authConstants.LOGOUT_USER:
      return {
        ...state,
        user: null
      };

    default:
      return state;
  }
};

export default authReducer;
