const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  basename: '/',
  // defaultPath: '/dashboard/default',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  // API_ENPOINT: 'http://localhost:8881',
  // API_ENPOINT: 'http://slink.asia:8881',
  // API_ENPOINT: 'https://autolive.tech:8444',
  API_ENPOINT: 'https://slive.vn:8444',
  API_VERSION: '/v1',
  API_VERSION_2: '/v2',
  OTHER_BASE_URL: 'https://api.vietqr.io/v2'
};

export default config;
