import { ValidatorForm } from 'react-material-ui-form-validator';

// Đăng ký hàm validator
ValidatorForm.addValidationRule('isPasswordValid', (value) => {
  return /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$!%*?&]{8,}$/.test(value);
});

ValidatorForm.addValidationRule('isPhoneValid', (value) => {
  return /^(?:\+84|84|0)\d{9,10}$/.test(value);
});
