import axios from 'axios';
import config from '../config';

const API_PATH = config.API_ENPOINT;
const API_VERSION = config.API_VERSION;

export const registerUser = (data) => {
  return axios.post(API_PATH + API_VERSION + `/auth/register`, data);
};

export const loginUser = (data) => {
  return axios.post(API_PATH + API_VERSION + `/auth/login`, data);
};

export const logoutUser = () => {
  return axios.get(API_PATH + API_VERSION + `/auth/logout`);
};

export const sendOtp = (data) => {
  return axios.post(API_PATH + API_VERSION + `/otp/sendOTP`, data);
};

export const checkOtp = (data) => {
  return axios.get(API_PATH + API_VERSION + `/otp/checkOTP`, {
    params: {
      email: data.email,
      otp: data.otp
    }
  });
};
