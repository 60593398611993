import axios from 'axios';
import config from '../config';
const API_PATH = config.API_ENPOINT;
const API_VERSION_2 = config.API_VERSION_2;

export const getUserBalance = (userId) => {
  return axios.get(API_PATH + API_VERSION_2 + `/wallet/byUser/${userId}`);
};

export const getUserWalletHistory = (userId) => {
  return axios.get(API_PATH + API_VERSION_2 + `/wallet/byUser/history/${userId}`);
};
