import support from './support';
import adminFunctions from './admin';
import userFunctions from './user';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [adminFunctions, userFunctions, support]
};

export default menuItems;
