import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoutes from './AdminRoutes';
import UserRoutes from './UserRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes(props) {
  const { isAdmin } = props;
  return useRoutes(isAdmin ? [AdminRoutes, AuthenticationRoutes] : [UserRoutes, AuthenticationRoutes]);
}
