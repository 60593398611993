export const SUCCESS = 200;
export const FAIL = 'Thất bại';

export const PATH = {
  HOMEPAGE: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password'
};

export const PROFILE_SECTION = {
  ADMIN: { id: 0, name: 'Quản trị viên' },
  USER: { id: 1, name: 'Người dùng' },
  INFOR: { id: 2, name: 'Thông tin cá nhân' },
  CHANGEPASS: { id: 3, name: 'Đổi mật khẩu' },
  LOGOUT: { id: 4, name: 'Đăng xuất' }
};

export const TYPE_PASSWORD = {
  OLD: { id: 1, name: 'Mật khẩu cũ' },
  NEW: { id: 2, name: 'Mật khẩu mới' },
  CONFIRM: { id: 3, name: 'Xác nhận mật khẩu' }
};

export const TYPE_BUTTON = {
  CREATE: { id: 1, name: 'Thêm mới' },
  UPDATE: { id: 2, name: 'Sửa' },
  DELETE: { id: 3, name: 'Xóa' },
  APPROVE: { id: 4, name: 'Phê duyệt' },
  PAYMENT: { id: 5, name: 'Thanh toán' }
};

export const LIVE = {
  CREATE: { id: 1, name: 'Thêm mới live' },
  UPDATE: { id: 2, name: 'Sửa live' },
  DELETE: { id: 3, name: 'Xác nhận xóa' }
};

export const TYPE_SEND_OTP = {
  REGISTER: 'register',
  GENERAL: 'general'
};

export const TYPE_CONTACT = {
  EMAIL: { id: 1, name: 'email', email: 'autolive.tech@gmail.com' },
  PHONE: { id: 2, name: 'phone', phone: '0344730102' },
  ZALO: { id: 3, name: 'zalo', zalo: '0344730102' },
  MESSAGE: { id: 4, name: 'message', message: '127399787132574' }
};

export const INIT_PACKAGE = {
  name: '',
  price: '',
  freeDays: '',
  group: 'individual'
};

export const INIT_ACCOUNT_TYPE = {
  name: '',
  price: '',
  level: '',
  group: 'individual'
};

export const INIT_ORDER = {
  type: 'upgradeAccount',
  packageId: '',
  packageName: '',
  accountTypeId: '',
  accountTypeName: '',
  amount: 0
};

export const LIST_COLORS = ['#f05458', '#5083c4', '#f89834', '#01c862', '#a259ff'];

export const BASE_BANK_ACCOUNT_INFO = {
  accountNo: 18021367,
  accountName: 'NGUYEN KHAC SI',
  acqId: 970416,
  format: 'text',
  template: 'print'
};
