import { toast } from 'react-toastify';

export const alertMessage = (message) => {
  //sendOTP message
  if (message === 'OTP code has been sent, please try again after 5 minutes') {
    toast.error('Mã OTP đã được gửi, vui lòng thử lại sau 5 phút.');
  }
  if (message === 'User is already registered') {
    toast.error('Người dùng đã được đăng ký.');
  }
  if (message === 'This account does not exist') {
    toast.error('Tài khoản này không tồn tại.');
  }
  if (message === 'This user does not exist') {
    toast.error('Người dùng này không tồn tại.');
  }
  //checkOTP
  if (message === 'Invalid OTP') {
    toast.error('Mã OTP không hợp lệ. Vui lòng kiểm tra lại.');
  }
  if (message === 'The OTP code has expired, please resend another') {
    toast.error('Mã OTP đã hết hạn, vui lòng yêu cầu gửi lại mã khác.');
  }
  //check login
  if (message === 'Wrong user or password') {
    toast.error('Tên người dùng hoặc mật khẩu không chính xác.');
  }
  if (message === 'User is not active') {
    toast.error('Người dùng không hoạt động.');
  }
  //check register
  if (message === 'Invalid password') {
    toast.error('Mật khẩu chưa đúng định dạng. Vui lòng kiểm tra lại.');
  }
  //change password
  if (message === 'Wrong password') {
    toast.error('Mật khẩu cũ không chính xác.');
  }
  //update Order
  if (message === 'You can only edit recharge order') {
    toast.error('Bạn chỉ có thể chỉnh sửa loại giao dịch nạp tiền vào ví.');
  }
  if (message === 'You cannot edit an accepted order') {
    toast.error('Bạn không thể chỉnh sửa một giao dịch đã được phê duyệt.');
  }
  if (message === 'You are not authorized to update this order') {
    toast.error('Bạn không có quyền cập nhật giao dịch này.');
  }
  //approve order
  if (message === 'You cannot approve an accepted order') {
    toast.error('Giao dịch này đã được phê duyệt.');
  }
  if (message === 'Order not found') {
    toast.error('Giao dịch không được tìm thấy.');
  }
  //delete order
  if (message === 'You cannot delete an accepted order') {
    toast.error('Bạn không thể xoá giao dịch đã được phê duyệt.');
  }
  if (message === 'You are not authorized to delete this order') {
    toast.error('Bạn không có quyền xoá giao dịch này.');
  }
};
