import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//user routing
// const LiveList = Loadable(lazy(() => import('views/user/lives/list')));
const WalletPage = Loadable(lazy(() => import('views/user/WalletPage')));
const OrderPage = Loadable(lazy(() => import('views/user/OrderPage')));
const Statistic = Loadable(lazy(() => import('views/user/lives/statistic')));
const Contact = Loadable(lazy(() => import('views/contact')));

// ==============================|| MAIN ROUTING ||============================== //

const UserRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    // {
    //   path: '',
    //   element: <LiveList />
    // },
    {
      path: '',
      element: <OrderPage />
    },
    {
      path: 'wallet',
      element: <WalletPage />
    },
    {
      path: 'statistic',
      element: <Statistic />
    },
    {
      path: 'contact',
      element: <Contact />
    }
  ]
};

export default UserRoutes;
