// assets
import { IconUsers, IconGift, IconShoppingBag, IconAssembly } from '@tabler/icons';

// constant
const icons = { IconUsers, IconGift, IconShoppingBag, IconAssembly };
// ==============================|| Users MENU ITEMS ||============================== //

const adminFunctions = {
  id: 'admin-functions',
  title: 'Chức năng của admin',
  type: 'group',
  role: 'admin',
  children: [
    {
      id: '',
      title: 'Quản lý giao dịch',
      type: 'item',
      url: '/',
      icon: icons.IconShoppingBag,
      breadcrumbs: false
    },
    {
      id: 'user-management',
      title: 'Quản lý người dùng',
      type: 'item',
      url: '/user-management',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'Quản lý loại tài khoản',
      title: 'Quản lý loại tài khoản',
      type: 'item',
      url: '/setting/account-type',
      icon: icons.IconAssembly,
      breadcrumbs: false
    },
    {
      id: 'package-management',
      title: 'Quản lý gói cước',
      type: 'item',
      url: '/setting/package-management',
      icon: icons.IconGift,
      breadcrumbs: false
    }
  ]
};

export default adminFunctions;
