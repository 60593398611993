import { call, put, takeEvery } from 'redux-saga/effects';
import * as authConstants from '../constants/AuthConstants';
import * as authServices from '../../services/authService';
import { loginUserSuccess, registerUserFail, registerUserSucess } from 'store/actions/AuthActions';
import { FAIL, SUCCESS } from 'utils/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* handleRegisterUser(action) {
  try {
    const registerData = action.payload;
    const res = yield call(authServices.registerUser, registerData);

    if (res?.status === SUCCESS) {
      yield put(registerUserSucess(res?.data));
      toast.success('Đăng ký thành công');
    } else {
      toast.error(FAIL);
      yield put(registerUserFail(FAIL));
    }
  } catch (error) {
    toast.error(error?.response?.data);
    yield put(registerUserFail(error));
  }
}

function* handleLoginUser(action) {
  try {
    const loginData = action.payload;
    const res = yield call(authServices.loginUser, loginData);
    if (res?.status === SUCCESS) {
      yield put(loginUserSuccess(res?.data));
      toast.success('Đăng nhập thành công');
    }
  } catch (error) {
    const err = error?.response;
    if (err.status === 500) {
      toast.error('Email chưa tồn tại');
    }
    if (err?.status === 400) {
      toast.error('Email hoặc mật khẩu không đúng');
    }
    yield put(registerUserFail(error?.response?.data?.message));
  }
}

function* authSaga() {
  yield takeEvery(authConstants.REGISTER_USER, handleRegisterUser);
  yield takeEvery(authConstants.LOGIN_USER, handleLoginUser);
}
export default authSaga;
