// assets
import { IconPlayerPlay, IconReportSearch, IconWallet, IconShoppingBag } from '@tabler/icons';

// constant
const icons = { IconPlayerPlay, IconReportSearch, IconWallet, IconShoppingBag };
// ==============================|| LIVES MENU ITEMS ||============================== //

const userFunctions = {
  id: 'user-functions',
  title: 'Chức năng của người dùng',
  type: 'group',
  role: 'user',
  children: [
    // {
    //   id: 'LiveList',
    //   title: 'Danh sách live',
    //   type: 'item',
    //   url: '/',
    //   icon: icons.IconPlayerPlay,
    //   breadcrumbs: false
    // },
    {
      id: '',
      title: 'Quản lý giao dịch',
      type: 'item',
      url: '/',
      icon: icons.IconShoppingBag,
      breadcrumbs: false
    },
    {
      id: 'wallet',
      title: 'Ví của tôi',
      type: 'item',
      url: '/wallet',
      icon: icons.IconWallet,
      breadcrumbs: false
    }
    // {
    //   id: 'Statistic',
    //   title: 'Báo cáo thống kê',
    //   type: 'item',
    //   url: '/statistic',
    //   icon: icons.IconReportSearch,
    //   breadcrumbs: false
    // }
  ]
};

export default userFunctions;
